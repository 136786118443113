<template>
  <section class="home-block pt-0 text-white">
    <div ref="background" class="background" />
    <div class="content">
      <div class="container">
        <div ref="imageWrap" class="block-1">
          <div class="image__wrap">
            <div
              v-if="content.video"
              ref="image"
              class="video-background"
              :style="{ backgroundImage: 'url(' + content.img + ')' }"
            >
              <video
                class="lazy"
                autoplay
                muted
                loop
                playsinline
                preload="none"
                :data-src="content.video"
                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              />
            </div>
            <div v-else-if="content.img" ref="image" class="video-background">
              <img
                loading="lazy"
                width="1224"
                height="660"
                :src="content.img"
                :alt="content.title"
              />
            </div>
          </div>
          <div v-if="content.title" class="block-2">
            <div class="row">
              <div class="col-lg-8 offset-lg-4">
                <div ref="title" class="h2 title" v-html="content.title" />
              </div>
            </div>
          </div>
        </div>
        <div class="block-3">
          <div class="row">
            <div class="col-lg-8 offset-lg-4">
              <div class="list">
                <div ref="list" class="row">
                  <div
                    v-for="item in content.list"
                    :key="item.title"
                    class="col-md-6"
                  >
                    <div class="list__item">
                      <div
                        v-if="item.title"
                        class="h4 list__title"
                        v-html="item.title"
                      />
                      <div
                        v-if="item.text"
                        class="list__text"
                        v-html="item.text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  props: {
    content: {
      type: Object,
    },
  },
  mounted() {
    if (!is_touch()) {
      gsap.from(this.$refs.background, {
        opacity: 0,
        scrollTrigger: {
          trigger: this.$el,
          scrub: 1.5,
          start: "top bottom",
          end: "40% center",
        },
      });
      gsap.from(this.$refs.title, {
        y: -250,
        scrollTrigger: {
          trigger: this.$el,
          start: "top 80%",
          end: "center center",
          scrub: 1.5,
        },
      });
      gsap.from(this.$refs.list.children, {
        y: 150,
        stagger: 0.1,
        scrollTrigger: {
          trigger: this.$refs.list,
          start: "top bottom",
          end: "bottom 90%",
          scrub: 1.5,
        },
      });
      gsap.fromTo(
        this.$refs.image,
        { y: "-40%" },
        {
          y: "40%",
          scrollTrigger: {
            trigger: this.$el,
            scrub: 1,
          },
          ease: "none",
        }
      );
    }
  },
};
</script>

<style scoped>
.background {
  position: absolute;
  top: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #205640;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: url(~@i/text-mask.png);
  background-position: 50% 50%;
  background-size: 420px 560px;
  opacity: 0.4;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #205640 38.71%, rgb(32 86 64 / 0) 100%);
}

.content {
  position: relative;
}

.block-1 {
  position: relative;
}

.block-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.title {
  max-width: 500px;
  margin-bottom: 35px;
}

.image__wrap {
  position: relative;
  overflow: hidden;
  margin-bottom: 55px;
  padding-bottom: 54%;
}

.video-background {
  position: absolute;
  top: 0%;
  right: 0;
  bottom: -15%;
  left: 0;
  height: auto;
}

.list__title {
  margin: 0 0 22px;
}

.list__item {
  max-width: 340px;
}

.list__text {
  color: #d2ddd9;
}
@media (max-width: 991px) {
  .video-background {
    bottom: 0;
  }

  .image__wrap {
    margin-right: -50px;
    margin-left: -50px;
    padding-bottom: 70%;
  }
}
@media (max-width: 767px) {
  .image__wrap {
    margin-right: 0;
    margin-bottom: 35px;
    margin-left: 0;
  }

  .title {
    max-width: 100%;
    margin-bottom: 33px;
  }

  .block-2 {
    position: relative;
    bottom: auto;
  }

  .list {
    margin-bottom: -35px;
  }

  .list__item {
    max-width: 100%;
    margin-bottom: 35px;
  }
}
@media (max-width: 575px) {
  .image__wrap {
    margin-right: -15px;
    margin-left: -15px;
  }
}
</style>
