<template>
  <section class="home-block">
    <div class="container">
      <div ref="bottom" class="btns-list d-flex justify-content-center">
        <div class="btns-list__item">
          <button
            class="btn btn-default"
            data-popup-with-chats
            data-popup-with-chats-title="Выбрать машиноместо"
          >
            Выбрать машиноместо
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 70%",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(
          gsap.utils.toArray([this.$refs.bottom]),
          {
            y: 100,
            stagger: { each: 0.1 },
          },
          0
        );
    }
  },
};
</script>

<style scoped>
.btns-list {
  margin: 0;
}
@media (max-width: 767px) {
  .home-block {
    padding-top: 30px;
  }
}
</style>
