<template>
  <Head>
    <title>Подземный паркинг и кладовые {{ $titleEnd }}</title>
    <meta
      name="description"
      content="631 м/м в паркинге, 330 кладовых - в доме хватит места для всего, что вам дорого. Спускайтесь к автомобилю на лифте прямо от квартиры, освобождайте пространство от больших и сезонных вещей."
    />
    <meta
      property="og:title"
      :content="'Подземный паркинг и кладовые ' + $titleEnd"
    />
    <meta
      property="og:description"
      content="631 м/м в паркинге, 330 кладовых - в доме хватит места для всего, что вам дорого. Спускайтесь к автомобилю на лифте прямо от квартиры, освобождайте пространство от больших и сезонных вещей."
    />
  </Head>
  <main class="main my-0">
    <Hero
      :content="hero"
      :breadcrumbs="[
        { name: 'Home', text: 'Главная' },
        { name: 'Advantages', text: 'Преимущества' },
        { text: hero.title },
      ]"
    />
    <Basis :content="basis" />
    <Image :content="image" />
    <ParkingBtn />
    <Green class="krhrltjfsuqwrlik" :content="green" />
    <Others />
    <HomeChoose />
  </main>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Basis from "@/components/adv/inner/Basis.vue";
import Image from "@/components/adv/inner/Image.vue";
import ParkingBtn from "@/components/adv/inner/ParkingBtn.vue";
import Green from "@/components/adv/inner/Green.vue";
import Others from "@/components/adv/inner/Others.vue";
import HomeChoose from "@/components/home/Choose.vue";
import { Head } from "@vueuse/head";
export default {
  name: "Parking",
  components: {
    Head,
    Hero,
    Basis,
    Image,
    ParkingBtn,
    Green,
    Others,
    HomeChoose,
  },
  data() {
    return {
      hero: {
        title: "Подземный паркинг и&nbsp;кладовые",
        text: "<p>В&nbsp;доме хватит места для всего, что вам дорого. Спускайтесь к&nbsp;автомобилю на&nbsp;лифте прямо от&nbsp;квартиры, освобождайте пространство от&nbsp;больших и&nbsp;сезонных вещей.</p>",
        background: require("@i/html/parking/parking-hero.jpg"),
        video: require("@i/html/parking/parking.mp4"),
        more: "Узнайте больше",
      },

      basis: {
        title: 'Храним ваш <span class="text-masked">комфорт</span>',
        subtitle: "631&nbsp;м/м в&nbsp;паркинге<br> 330&nbsp;Кладовых",
        text: "<p>Кладовые от&nbsp;2,43 до&nbsp;9,45&nbsp;кв.&nbsp;м&nbsp;&mdash; выбирайте размер по&nbsp;своим планам и&nbsp;потребностям.</p>",
        list: [
          {
            title: "Машина в&nbsp;безопасности",
            text: "<p>Приехав домой, вам не&nbsp;придётся искать место для парковки. Спокойно оставляйте автомобиль в&nbsp;тёплом паркинге на &minus;1&nbsp;этаже.</p>",
            img: require("@i/html/parking/parking-m-2.jpg"),
          },
          {
            title: "Больше места в&nbsp;квартире",
            text: "<p>Храните в&nbsp;просторной кладовой спортинвентарь, материалы для ремонта и&nbsp;всё, чем редко пользуетесь дома.</p>",
            img: require("@i/html/parking/parking-m-4.jpg"),
          },
          {
            title: "Максимум пространства во&nbsp;дворе",
            text: "<p>Свободный от&nbsp;автомобилей двор&nbsp;&mdash; территория игр и&nbsp;отдыха. Можно с&nbsp;лёгкостью отпускать детей гулять самостоятельно.</p>",
            img: require("@i/html/parking/parking-m-3.jpg"),
          },
        ],
      },

      image: {
        title: "Удовольствие",
        text: "<p>В&nbsp;любую погоду ваш автомобиль ждет вас в&nbsp;теплом пространстве с&nbsp;приятным интерьером. Нет необходимости выходить на&nbsp;улицу в&nbsp;дождь или снег.</p>",
        img: require("@i/html/parking/parking.jpg"),
      },

      green: {
        title: "Вместительная кладовая",
        img: require("@i/html/parking/parking-green.jpg"),
        list: [
          {
            title: "Порядок везде",
            text: "<p>Кладовые от&nbsp;2,43 до&nbsp;9,45&nbsp;кв.&nbsp;м&nbsp;&mdash; выбирайте размер по&nbsp;своим планам и&nbsp;потребностям.</p>",
          },
          {
            title: "Больше места в&nbsp;квартире",
            text: "<p>В&nbsp;доме хватит места для всего, что вам дорого. Храните в&nbsp;просторной кладовой спортинвентарь, материалы для ремонта и&nbsp;всё, чем редко пользуетесь дома.</p>",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.main {
  background: #f8f8f8;
}
@media (max-width: 374px) {
  .krhrltjfsuqwrlik :deep(.title) {
    font-size: 49px;
  }
}
</style>
